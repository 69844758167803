<template>
  <div>
    <!--    tips-->
    <div class="tips">为了确保测额的准确性，以下信息需要如实填写，谢谢配合!</div>
    <div class="ce-title">贷款测额：能贷多少？</div>
    <div class="ce-title-t"><span>1分钟</span> 评估后您就知道</div>
    <form class="form-box">
      <div class="l-input">
        <input class="input-con" v-model="formData.user" type="text" placeholder="请输入您的姓名"/>
      </div>
      <div class="l-input">
        <input class="input-con" v-model="formData.phone" type="number"
               oninput="if(value.length>11)value=value.slice(0,11)"
               placeholder="请输入您的手机号码"/>
      </div>
      <div class="l-input felx flex-space">
        <input class="input-con" v-model="formData.code" type="number" placeholder="请输入您验证码"/>
        <div class="line"></div>
        <div :class="['code-text',{'active-code':isActive}]" @click="getCode">
          {{ isActive ? num + 's后重新获取' : '获取验证码' }}
        </div>
      </div>
      <div class="l-input felx flex-space">
        <span class="ce-text">房产地区</span>
        <div @click="show=!show">{{ area.length ? area[0].name + ' ' + area[1].name + ' ' + area[2].name : '选择地区' }}
        </div>
        <img src="../../assets/images/xiala@2x.png" class="xiala" alt="">
      </div>
      <div class="l-input felx flex-space">
        <span class="ce-text">房屋总价</span>
        <input type="number" v-model="formData.housingPrice" class="input-con ce-input"/>
        <span class="ce-text">(万元)</span>
      </div>
      <div class="l-input felx flex-space">
        <span class="ce-text">是否有抵押</span>
        <div class="felx">
          <div class="shi-text" @click="formData.haveSecondLoan=1">
            <img v-if="formData.haveSecondLoan" src="../../assets/images/shi@2x.png" class="shi">
            <img v-else src="../../assets/images/fou@2x.png" class="shi">
            <span>是</span>
          </div>
          <div class="shi-text" @click="formData.haveSecondLoan=0">
            <img v-if="!formData.haveSecondLoan" src="../../assets/images/shi@2x.png" class="shi">
            <img v-else src="../../assets/images/fou@2x.png" class="shi">
            <span>否</span>
          </div>
        </div>
      </div>
      <div class="l-input felx flex-space" v-if="formData.haveSecondLoan==1">
        <span class="ce-text">一押贷款余额</span>
        <input type="number" v-model="formData.firstLoanBalance" class="input-con ce-input"/>
        <span class="ce-text">(万元)</span>
      </div>
      <div class="btn" @click="submit">立即测额</div>
      <div class="user-num">您是13420位进行贷款测额的用户</div>

    </form>
    <!--    测额成功-->
    <measuringAmountSuccess :phone="formData.phone" :lines="lines" v-if="showCESucc" @close="showCESucc=!showCESucc"></measuringAmountSuccess>
    <measuringForeheadFailure v-if="showCEFail" @close="showCEFail=!showCEFail"></measuringForeheadFailure>
    <applicationSuccessful v-if="showSucc" @close="showSucc=!showSucc"></applicationSuccessful>

    <van-popup v-model="show" position="bottom" round>
      <van-area @cancel="show=false" @confirm="confirm" :area-list="areaList" value="500000"/>
    </van-popup>
  </div>

</template>

<script>
import measuringAmountSuccess from '../../components/measuring-amount-success';
import measuringForeheadFailure from '../../components/measuring-forehead-failure';
import applicationSuccessful from '../../components/application-successful';
import {areaList} from '@vant/area-data';
import {Toast} from "vant";

export default {
  name: "loan-amount-measurement",
  components: {measuringAmountSuccess, measuringForeheadFailure, applicationSuccessful},
  data() {
    return {
      showCESucc: false,//显示测额成功
      showCEFail: false,//显示测额失败
      showSucc: false,//显示申请成功
      areaList, //省市区数据
      show: false,//是否显示弹出层
      area: [],//选择的地区
      isActive: false,//是否获取验证码
      num: 60,//倒计时
      lines:'',//测额额度
      formData: {
        user: '',//姓名
        phone: '', //手机号
        code: '',//验证码
        province: '',//省
        city: '',//市
        region: '',//区
        housingPrice: '',//房屋总价
        haveSecondLoan: 0,//是否有抵押
        firstLoanBalance: '',//一押贷款余额
      },
    };
  },
  methods: {
    //提交
    submit() {
      // this.formData.firstLoanBalance=+this.formData.firstLoanBalance;
      // this.formData.housingPrice=+this.formData.housingPrice;
      this.$axios.post(`/business/mobile/measuring`,this.formData).then(res=>{
        console.log(res)
        if(res.data.data==0){
          //测额失败
          this.showCEFail=true;
        }else {
          this.showCESucc=true;
          this.lines=res.data.data;
        }
      })
    },
    //倒计时
    countdown() {
      let timer = setInterval(() => {
        --this.num;
        if (this.num <= 0) {
          this.isActive = false;
          this.num = 60;
          clearInterval(timer);
        }
      }, 1000)
    },
    //获取验证嘛
    getCode() {
      if (!this.isActive) {
        if (this.formData.phone.length == 11) {
          this.$axios.get(`/business/mobile/getCode/${this.formData.phone}`).then(() => {
            this.isActive = true;
            this.countdown();
          })
        } else {
          Toast('填写完整手机号')
        }
      }

    },
    //选择地区
    confirm(e) {
      console.log(e);
      this.show = false;
      this.area = e;
      this.formData.province=this.area[0].name;
      this.formData.city=this.area[1].name;
      this.formData.region=this.area[2].name;
    },
  },
}
</script>

<style scoped>
.tips {
  height: 30px;
  background: rgba(251, 86, 10, 0.15);
  width: 100%;
  font-size: 12px;
  color: #FB560A;
  line-height: 30px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.ce-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 15px;
}

.ce-title-t {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;
}

.ce-title-t span {
  color: #FB560A;
}

.form-box {
  padding: 0 25px;
}

.ce-text {
  font-size: 16px;
  color: #333333;
  line-height: 44px;
}

.xiala {
  width: 10px;
  height: 6px;
}

.ce-input {
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  padding: 0 15px;
  height: 44px;
}

.shi {
  width: 12px;
  height: 12px;
  margin: 0 5px 0 15px;

}

.shi-text {
  font-size: 16px;
  color: #333333;
}

.user-num {
  font-size: 13px;
  color: #999999;
  margin-top: 15px;
  text-align: center;
  margin-bottom: 30px;
}

</style>
<template>
  <div class="container felx flex-center">
    <div class="content">
      <img @click="close" src="../assets/images/guanbi.png" class="guanbi"/>
      <div class="title">恭喜您</div>
      <div class="title">您的可贷额度</div>
      <div class="lines"><span>￥</span>{{lines}}</div>
      <div class="btn" @click="tie">申请提额</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "measuring-amount-success",
  props:{
    lines:String,
    phone:String,
  },
  methods:{
    //申请提额
    tie(){
        this.$axios.get(`/business/mobile/increase/${this.phone}`).then(()=>{
          this.$parent.showCESucc=false;
          this.$parent.showSucc=true;
        })

    },
    close(){
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  touch-action: none;
}

.content {
  width: 315px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  padding: 30px 15px 0;
}

.guanbi {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.title{
  text-align: center;
  margin-bottom: 13px;
  font-size: 18px;
  color: #333333;
}
.lines{
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.lines span{
  font-size: 18px;
}


</style>
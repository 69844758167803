<template>
  <div class="container felx flex-center">
    <div class="content">
      <img @click="close" src="../assets/images/guanbi.png" class="guanbi"/>
      <div class="title">很遗憾</div>
      <div class="title">您的可贷额度</div>
      <div class="lines"><span>￥</span>0</div>
      <a href="tel:023-67456888" class="btn">联系客服</a>
      <div class="tips">可联系客服进行调额</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "measuring-forehead-failure",
  methods:{
    close(){
      this.$emit('close')
    },
  },

}
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  touch-action: none;
}

.content {
  width: 315px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  padding: 30px 15px 0;
}

.guanbi {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.title{
  text-align: center;
  margin-bottom: 13px;
  font-size: 18px;
  color: #333333;
}
.lines{
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.lines span{
  font-size: 18px;
}

.tips{
  text-align: center;
  font-size: 11px;
  color: #999999;
  margin-top: 5px;
}
</style>